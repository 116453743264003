import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/@apollo/experimental-nextjs-app-support/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Banner"] */ "/app/src/app/mx/banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/psicologia-viva-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/queima-diaria-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/smart-coach-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/total-play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/zen-app-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/exclusive-content-benefits.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/meal-plan-benefits.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalfit-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalfood-benefit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalmind-benefit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalnutri-benefit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/app-totalpass.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/client-tp.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/company-contact.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/contact.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/dealing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/estimate.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/first-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/form.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/megaphone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/second-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/select-plan.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/third-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/workOut.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/canais-indiretos-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/collaborators-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/gyms-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/home-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/bio-ritmo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/buddha-spa-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/check-bullet-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/jab-house-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/kore.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/oazi-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/our-advantages-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/race-bootcamp.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/sky-fit-icon.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/smart-fit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/tecfit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/tonus-gym.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/velocity.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/vidya-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/bio-ritmo-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/buddha-spa-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/cross-experience-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/cross-life-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/daytona-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/evoque-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/jab-house-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/kore-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/life-lift-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/logo_academia24h.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/logo_smartfit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/moinhos-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/nation-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/race-bootcamp-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/sky-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/target-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/ultra-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/velocity-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/vidya-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/xprime-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/icons/apple-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/icons/play-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/app/app-collaborators-background-mx.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/app/app-home-background-mx.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/benefits/totalfit-benefit.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/benefits/totalmind-benefit.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/benefits/totalnutri-benefit.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/gym-search/gym-search-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/information/collaborators-gym-propose-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/information/company-gym-propose-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/information/who-we-are-propose-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/box.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/cross-training.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/danza.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/hipertrofia.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/masajes.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/natacion.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/modalities/pilates.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-advantages/evo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/9round.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/anytime.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/beatness.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/commando.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/fitpass.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/fitspin.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/nelson-vargas.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/orangetheory.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/seven-days-gold.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/sport-city.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/sportica.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/sportium.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/sports-world.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/station.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/wolf.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/gyms/ymca.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/herdez.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-axa.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-bbva.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-cornershop.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-danone.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-forbes.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-hsbc.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-nestle.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-santander.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-televisa.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-uber.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/our-partners/home/logo-wallmart.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/11-11-fitness.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/99round.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/axa.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/bbva.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/logo-teleperformance.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/smart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/sport-city.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/mx/sections/testimonials/ymca.svg");
;
import(/* webpackMode: "eager", webpackExports: ["App"] */ "/app/src/components/sections/app/app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Benefits"] */ "/app/src/components/sections/benefits/benefits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/app/src/components/sections/faq/faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GymSearch"] */ "/app/src/components/sections/gymSearch/gymSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HowItWorks"] */ "/app/src/components/sections/howItWorks/howItWorks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InformationBanner"] */ "/app/src/components/sections/informationBanner/informationBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Modalities"] */ "/app/src/components/sections/modalities/modalities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurAdvantages"] */ "/app/src/components/sections/ourAdvantages/ourAdvantages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurClients"] */ "/app/src/components/sections/ourClients/ourClients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Plans"] */ "/app/src/components/sections/plans/plans.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Testimonials"] */ "/app/src/components/sections/testimonial/testimonial.tsx");
